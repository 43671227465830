<template>
  <div class="edit-document-details">
    <!-- Header Section -->
    <header class="document-header">
      <h2 class="header-title">编辑文档</h2>
      <el-button type="primary" class="save-button" @click="saveDocument">保存</el-button>
    </header>

    <div class="content-container">
      <div class="word-preview">
        <h3>{{ filename }}</h3>
        <div id="placeholder" class="document-content"></div>
      </div>

      <div class="placeholder-settings">
        <h3>占位符设置</h3>
        <el-form :model="placeholderForm" label-width="120px" class="form-container">
          <el-form-item label="选择占位符类型">
            <el-select v-model="placeholderType" @change="generatePlaceholderName" placeholder="请选择占位符类型">
              <el-option label="文字" value="text"></el-option>
              <el-option label="图片" value="image"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="占位符名称">
            <el-input v-model="placeholderForm.placeholderName" placeholder="输入变量名称" @input="updatePlaceholderName"></el-input>
          </el-form-item>
        </el-form>
        <el-button type="success" @click="insertPlaceholder">插入占位符</el-button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */  // This line disables all ESLint warnings in this file
import axios from 'axios';
import { ElMessageBox, ElMessage } from 'element-plus';
export default {
  name: "EditDocumentDetails",
  data() {
    return {
      filename:"",
      placeholderType: "text",
      placeholderForm: {
        placeholderName: "", // 动态生成的占位符名称
      },
      docEditor: null,
      connector: null,
      textPlaceholderCount: 1, // 用于文本占位符的计数
      imagePlaceholderCount: 1, // 用于图片占位符的计数
    };
  },
  mounted() {
    const nodeId = this.$route.params.id; // 从路由参数中获取 nodeId

    if (!nodeId) {
      console.error("缺少 nodeId 参数");
      return;
    }

    // 请求文档路径并加载 OnlyOffice
    this.fetchDocumentTemplate(nodeId)
        .then((fileUrl) => {
          this.loadOnlyOfficeAPI()
              .then(() => {
                this.initDocEditor(fileUrl,nodeId);
              })
              .catch((error) => {
                console.error("Failed to load OnlyOffice API:", error);
              });
        })
        .catch((error) => {
          console.error("Failed to fetch document template:", error);
        });

    // 初始化占位符名称
    this.generatePlaceholderName();
  },
  methods: {
    async fetchDocumentTemplate(nodeId) {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.post(
            `${process.env.VUE_APP_HOST}/api/documents/getTemplateByNode`,
            { nodeId },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
        );

        if (response.data && response.data.code === 1) {
          this.filename = response.data.data.file.nodeName;
          return `${process.env.VUE_APP_HOST}${response.data.data.file.path}`; // 返回文件完整路径
        } else {
          this.$message.error(response.data.msg || "获取文档模板失败");
          throw new Error("Failed to fetch document template");
        }
      } catch (error) {
        this.$message.error("请求文档模板时发生错误");
        throw error;
      }
    },
    loadOnlyOfficeAPI() {
      return new Promise((resolve, reject) => {
        if (typeof DocsAPI !== "undefined") {
          resolve();
        } else {
          const script = document.createElement("script");
          script.src = `${process.env.VUE_APP_IP}/web-apps/apps/api/documents/api.js`;
          script.onload = resolve;
          script.onerror = reject;
          document.head.appendChild(script);
        }
      });
    },
    initDocEditor(fileUrl,nodeId) {
      const randomKey = `${nodeId}_${Date.now()}_${Math.floor(Math.random() * 10000)}`;
      const config = {
        documentType: "word",
        document: {
          title: "Example Document Title.docx",
          url: fileUrl,
          fileType: "docx",
          key: randomKey,
        },
        editorConfig: {
          mode: "edit",
          lang: "zh-CN",
          customization: {
            autosave: true,
            chat: false,
            about: false,
            comments: false,
            logo: {
              image: `${process.env.VUE_APP_HOST}/img/cnxh.png`,
              imageDark: `${process.env.VUE_APP_HOST}/img/cnxh.png`,
              url: `${process.env.VUE_APP_HOST}`,
              visible: true,
            },
            features: {
              spellcheck: false
            },
            feedback: {
              url: `${process.env.VUE_APP_HOST}/feedback`,
              visible: true,
            },
            hideRulers: true,
            compactHeader: true,
            anonymous: {
              request: false, // 禁用协作名称提示框
              label: "Guest"   // 设置默认协作名称
            },
            layout: {
              leftMenu: false,   // Hide the left menu
              rightMenu: false,  // Hide the right menu
              toolbar: false,
            }
          },
        },
        events: {
          onDocumentReady: this.createConnector,
        },
        height: "95%",
        width: "100%",
      };

      // eslint-disable-next-line no-undef
      this.docEditor = new DocsAPI.DocEditor("placeholder", config);
    },
    createConnector() {
      this.connector = this.docEditor.createConnector();
    },
    generatePlaceholderName() {
      // 根据占位符类型自动生成初始名称
      if (this.placeholderType === "text") {
        this.placeholderForm.placeholderName = `{{text${this.textPlaceholderCount++}}}`;
      } else if (this.placeholderType === "image") {
        this.placeholderForm.placeholderName = `{{img${this.imagePlaceholderCount++}}}`;
      }
    },
    updatePlaceholderName() {
      // 在用户自定义输入时，确保名称包含 {{ }}
      const name = this.placeholderForm.placeholderName.replace(/[{}]/g, '');  // 去掉已有的大括号
      this.placeholderForm.placeholderName = `{{${name}}}`;
    },
    insertPlaceholder() {
      // 获取占位符名称
      const placeholder = this.placeholderForm.placeholderName;

      // 检查 OnlyOffice 的 connector 是否已初始化
      if (!this.connector) {
        console.error("Connector is not initialized.");
        return;
      }

      // 使用 OnlyOffice API 插入占位符到文档中
      Asc.scope.text = placeholder;
      this.connector.callCommand(function() {
        const doc = Api.GetDocument();
        const paragraph = Api.CreateParagraph();
        paragraph.AddText(Asc.scope.text);
        doc.InsertContent([paragraph]);
      }, function() {
        console.log("占位符已插入到文档中");
      });

      // 插入后生成下一个占位符的默认名称
      this.generatePlaceholderName();
    }
  },
};
</script>

<style scoped>
.edit-document-details {
  padding: 20px;
}

.content-container {
  display: flex;
  justify-content: space-between;
}

.word-preview {
  flex: 2;
  margin-right: 20px;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #ddd;
  height: 100vh;
  overflow-y: auto;
  position: relative;
}

.placeholder-settings {
  flex: 1;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #ddd;
  position: sticky;
  top: 20px;
  max-height: 80vh;
  overflow-y: auto;
}

.form-container {
  max-width: 100%;
}
.word-preview h3{
  text-align: center;
  margin:10px 0;
}
.document-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white-color);
  padding: 15px 20px;
  border-radius: 8px;
  color: white;
  margin-bottom: 20px;
}

.header-title {
  font-size: 20px;
  font-weight: bold;
}

.save-button {
  background-color: var(--secondary-color);
  border: none;
}

</style>
