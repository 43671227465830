<template>
  <div class="filling-tab-container">
    <div class="content-container">
      <div class="word-preview">
        <h3 style="text-align: center">{{filename}}</h3>
        <div
            id="placeholder"
            class="document-content"
            :class="{ 'no-template': !currentUrl }"
        >
          <div v-if="!currentUrl" class="placeholder-message">
            请点击右侧的具体目录
          </div>
        </div>
      </div>
      <!-- 右侧区域 -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { ElMessage } from "element-plus";

/* global DocsAPI, Asc, Api */
export default {
  name: "FillingTab",
  props: {
    dialogForm: {
      type: Object,
      required: true,
    },
    tabData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isDownloading: false,
      method : "",
      nodeId : "",
      filename : "",
      temp_id : "",
      currentUrl: "",
      menuItems: [],
      user_file_path:null,
    };
  },
  tabData: {
    handler(newVal) {
      if (newVal && newVal.id) {
        // 获取右侧菜单项
        // this.fetchSubMenuItems(newVal.id);
        // 重置视图
        this.resetToDefaultView();
        // 设置当前文档 URL
        this.currentUrl = `${process.env.VUE_APP_HOST}/path/to/document/${newVal.id}`;

        // 初始化 OnlyOffice 编辑器
        if (this.currentUrl) {
          this.initDocEditor(newVal.label);
        } else {
          console.error("currentUrl 未设置，无法初始化编辑器");
        }
      }
    },
    immediate: true, // 初始化时立即触发
  },

  methods: {
    // 向文档中插入内容 start
    insertText(data) {
      Asc.scope.text = data.text;
      this.connector.callCommand(
          function () {
            const doc = Api.GetDocument();
            const paragraph = Api.CreateParagraph();
            paragraph.AddText(Asc.scope.text);
            doc.InsertContent([paragraph]);
          },
          function () {
            ElMessage.success("占位符已成功插入到文档中");
          },
          function (error) {
            ElMessage.error("插入占位符失败");
          }
      );

      // 生成新的占位符名称
      this.generatePlaceholderName();
    },

    generatePlaceholderName() {
      if (!this.textPlaceholderCount) this.textPlaceholderCount = 1;
      if (!this.imagePlaceholderCount) this.imagePlaceholderCount = 1;
    },
    // 向文档中插入内容 end
    handleDocument(filename, temp_id, nodeId, method) {
      if (!this.docEditor) {
        ElMessage.warning("文档编辑器未加载，请先选择一个目录加载文档");
        return;
      }
      this.filename = filename;
      this.temp_id = temp_id;
      this.method = method;
      this.nodeId = nodeId;
      this.docEditor.downloadAs({ format: 'docx', title: this.filename });
    },
    // 重置到默认背景
    resetToDefaultView() {
      this.currentUrl = ""; // 清空文档 URL
    },
    loadOnlyOfficeAPI() {
      return new Promise((resolve, reject) => {
        if (typeof DocsAPI !== "undefined") {
          resolve();
        } else {
          const script = document.createElement("script");
          script.src = `${process.env.VUE_APP_IP}/web-apps/apps/api/documents/api.js`;
          script.onload = resolve;
          script.onerror = reject;
          document.head.appendChild(script);
        }
      });
    },
    // 初始化文档编辑器
    initDocEditor(docTitle) {
      // 检查是否存在现有编辑器实例，如果存在则先销毁
      if (this.docEditor) {
        this.docEditor.destroyEditor(); // 销毁编辑器实例
        this.docEditor = null; // 清空实例
      }

      const config = {
        documentType: "word",
        document: {
          title: docTitle || "未命名文档", // 动态设置文档标题
          url: this.currentUrl, // 当前文档 URL
          fileType: "docx",
          key: `${new Date().getTime()}_${Math.random().toString(36).substr(2, 5)}`, // 确保每次生成唯一 key
        },
        editorConfig: {
          mode: "edit",
          lang: "zh-CN",
          customization: {
            autosave: true,
            zoom: 80, // 设置默认缩放比例为 90%
            chat: false,
            about: false,
            comments: false,
            anonymous: { request: false, label: "Guest" },
            logo: {
              image: `${process.env.VUE_APP_HOST}/img/cnxh.png`,
              visible: true,
            },
            features: { spellcheck: false },
            hideRulers: true,
            compactHeader: true,
            showHeader: false, // 隐藏头部菜单
            showFooter: false, // 新增：隐藏底部状态栏
            layout: {
              leftMenu: false, // 隐藏左侧菜单
              rightMenu: false, // 隐藏右侧菜单
              toolbar: false, // 隐藏工具栏
              statusBar: false, // 隐藏状态栏
            },
          },
        },
        events: {
          // onDocumentReady: this.createConnector,
          onDownloadAs: this.handleDownloadAs, // 绑定下载事件
          onDocumentReady: () => {
            this.createConnector(); // 在文档加载完成后创建连接器
          },
        },
        height: "100%",
        width: "100%",
      };

      // eslint-disable-next-line no-undef
      this.docEditor = new DocsAPI.DocEditor("placeholder", config);
    },

    // 处理下载事件
    handleDownloadAs(data) {
      const sanitizedFilename = this.filename.endsWith('.docx')
          ? this.filename
          : `${this.filename}.docx`;


      const token = localStorage.getItem('token');
      const apiUrl = `${process.env.VUE_APP_HOST}/api/reports/download`;
      const documentCurrentInfo = {
        file_url: data.data.url,
        filename: sanitizedFilename,
        temp_id: this.temp_id,
        nodeId: this.nodeId,
        method: this.method,
      };
      console.log("Emitting documentCurrentInfo:", documentCurrentInfo); // 检查是否触发
      this.$emit("documentCurrentInfo", documentCurrentInfo); // 确保触发了事件
      axios
        .post(
            apiUrl,
            {
              file_url: data.data.url,
              filename: sanitizedFilename,
              temp_id: this.temp_id,
              nodeId: this.nodeId,
              method: this.method,
            },
            {
              responseType: 'blob', // 确保获取二进制数据
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
        )
        .then((response) => {
          if(this.method == "download"){
            const blob = new Blob([response.data]);
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = sanitizedFilename;
            document.body.appendChild(link);

            // 模拟点击以启动下载
            link.click();

            // 设置短时间后销毁链接（即使取消下载，也不会重复）
            setTimeout(() => {
              window.URL.revokeObjectURL(link.href);
              document.body.removeChild(link);
            }, 100);
          }else{
            // **解析 Blob 数据为 JSON**
            const reader = new FileReader();
            reader.onload = () => {
              const json = JSON.parse(reader.result);
              // 将解析的 JSON 数据传递给父组件
              const returnData = {
                  "path":json.data,
                  "method":this.method,
              }
              this.$emit("updateFileData", returnData);
            };
            reader.readAsText(response.data); // 读取 Blob 内容
            if(this.method === "save"){
              ElMessage.success(sanitizedFilename + "，保存成功");
            }

          }
        })
        .catch((error) => {
        });
    },


    createConnector() {
      this.connector = this.docEditor.createConnector();
    },
    // 请求右侧菜单项
    async fetchSubMenuItems(tempId) {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.post(
            `${process.env.VUE_APP_HOST}/api/documents/getMainMenuNavigation`,
            { temp_id: tempId },
            { headers: { Authorization: `Bearer ${token}` } }
        );

        if (response.data && response.data.code === 1) {
          this.menuItems = this.flattenMenu(response.data.data.menu);
        } else {
          this.menuItems = [];
          ElMessage.error("获取菜单失败");
        }
      } catch (error) {
        this.menuItems = [];
        console.error("请求菜单失败:", error);
      }
    },
    // 将层级数据拍平，添加 level 信息
    flattenMenu(menu, level = 0) {
      return menu.reduce((acc, item) => {
        const currentItem = { ...item, level };
        const children = item.children
            ? this.flattenMenu(item.children, level + 1)
            : [];
        return acc.concat([currentItem, ...children]);
      }, []);
    },
    // 点击右侧菜单项加载文档
    async onMenuItemClick(item) {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.post(
            `${process.env.VUE_APP_HOST}/api/documents/getTemplateByNode`,
            { nodeId: item.id },
            { headers: { Authorization: `Bearer ${token}` } }
        );

        if (response.data && response.data.code === 1) {
          const template = response.data.data.file;
          this.filename = response.data.data.file.nodeName;
          if (template && template.path) {
            this.currentUrl = `${process.env.VUE_APP_HOST}${template.path}`;
            this.initDocEditor();
          } else {
            ElMessage.error("该节点未找到模板文件");
          }
        } else {
          ElMessage.error(response.data.msg || "获取模板失败");
        }
      } catch (error) {
        console.error("获取模板失败:", error);
        ElMessage.error("请求模板失败");
      }
    },
  },
  mounted() {
    this.loadOnlyOfficeAPI().catch((error) => {
      console.error("Failed to load OnlyOffice API:", error);
    });
  },
};
</script>
<style scoped>
.filling-tab-container {
  display: flex;
  flex-direction: column;
}

.content-container {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 100px); /* 减去 header 的高度 */
  overflow: hidden;
}

.word-preview {
  flex: 7;
  background-color: #f9f9f9;
  padding: 0px;
  border-radius: 8px;
  border: 1px solid #ddd;
  height: 100%;
  overflow-y: auto;
  position: relative;
}

.document-content {
  height: 100%;
  position: relative;
}

.document-content.no-template {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  color: #999;
  font-size: 16px;
}

.placeholder-message {
  text-align: center;
}

.side-panel {
  flex: 3;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #ddd;
  overflow-y: auto;
  height: 100%; /* 明确设置高度 */
}

.table-cell {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 10px;
  box-sizing: border-box;
}

.name-column {
  width: 70%;
  text-align: left;
}

.action-column {
  width: 30%!important;
  text-align: center;
}
.template-status {
  display: flex;
  justify-content: center;
  align-items: center;
}

.has-template {
  color: green;
  font-weight: bold;
}

.no-template {
  color: red;
  font-weight: bold;
}
.template-status {
  text-align: center;
  font-weight: bold;
}

.has-template {
  color: green;
}

.no-template {
  color: red;
}
</style>
