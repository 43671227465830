<template>
  <el-dialog
      v-model="localVisible"
      title="编辑"
      width="80%"
      :before-close="closeDialog"
  >
    <div class="table-editor-layout">
      <!-- 左侧：编辑 HTML 部分 -->
      <div class="html-editor-section" style="overflow-y:auto">
        <div ref="editableContent" contenteditable="true" class="html-editable-container" @input="updateContent"></div>

        <div v-if="!originalHtml && !localVisible" class="loading-container">
          正在加载内容，请稍候...
        </div>
      </div>

      <!-- 右侧：已关联的公式 -->
      <div class="formula-list-section">
        <h4>已关联的公式：</h4>
        <el-table :data="selectedFormulas" border>
          <el-table-column prop="name" label="公式名称"></el-table-column>
          <el-table-column prop="description" label="描述"></el-table-column>
          <el-table-column prop="expression" label="公式表达式"></el-table-column>
          <el-table-column label="操作">
            <template #default="scope">
              <el-button size="mini" type="primary" @click="handleFormulaSelected(scope.row)">
                选择公式
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <!-- 公式编辑器弹窗 -->
    <!-- 公式编辑器弹窗 -->
    <el-dialog
        v-model="isFormulaDialogVisible"
        :title="'公式编辑器：' + formulaData.name"
        width="600px"
        :before-close="closeFormulaDialog"
    >
      <div class="formula-editor-container">
        <el-form :model="formulaData">
          <!-- Formula Expression Field (only display, not editable) -->
          <el-form-item label="公式表达式">
            <div class="formula-expression">
              {{ formulaData.expression }}
            </div>
          </el-form-item>

          <!-- Formula Variables Field (each variable in a separate line) -->
          <el-form-item label="公式变量">
            <div v-for="(variable, index) in formulaVariables" :key="index" class="variable-row">
              <!-- 变量名和下拉框在同一行显示 -->
              <div class="variable-name">
                <span v-if="variable.type === 'constant'">常量{{ variable.name }}:</span>
                <span v-else-if="variable.type === 'result'" style="color: red">结果{{ variable.name }}:</span>
                <span v-else>变量{{ variable.name }}:</span>

              </div>
              <div class="input-row result" v-if="variable.type === 'result'">{{mathResult}}</div>
              <div class="input-row" v-if="variable.type !== 'result'">
                <!-- 下拉选择框 -->
                <el-select
                    v-model="variable.value"
                    :placeholder="`选择 ${variable.name} 的值`"
                    clearable
                >
                  <!-- 填充下拉框的选项 -->
                  <el-option
                      v-for="(option, idx) in dropdownOptions"
                      :key="idx"
                      :label="option.label"
                      :value="option.value"
                  ></el-option>
                </el-select>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>

      <template #footer>
        <el-button @click="saveTableStructure">储存方案</el-button>
        <el-button @click="calculateResult">计算结果</el-button>
        <el-button @click="closeFormulaDialog">取消</el-button>
        <el-button type="primary" @click="applyFormula">应用公式</el-button>
      </template>
    </el-dialog>





    <template #footer>
<!--      <el-button @click="openFormulaDialog">选择公式2</el-button>-->
      <el-button @click="closeDialog">取消</el-button>
      <el-button type="primary" @click="saveEditedContent">保存</el-button>
    </template>
  </el-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "TableEditor",
  props: {
    isVisible: { type: Boolean, required: true },
    fileData: { type: Object, required: true },
    documentInfo: { type: Object, required: true },
  },
  data() {
    return {
      dropdownOptions: [], // 用来存储下拉框数据
      localVisible: this.isVisible,
      originalHtml: "",
      isFormulaDialogVisible: false,
      formulaVariables: [],
      selectedFormulas: [],
      formulaData: {
        id: "",
        name: "",
        expression: "",
        selectedVariable: [],
      },
      cursorContext: null,
      mathResult: 0,
      table:"",
    };
  },
  watch: {
    isVisible(newValue) {
      this.originalHtml = "<p>正在加载文档</p>";
      const editableContainer = this.$refs.editableContent;
      if (editableContainer) {
        editableContainer.innerHTML = this.originalHtml;
      }
      this.localVisible = newValue;
      if (newValue && this.fileData?.data) {
        this.loadHtmlContent();
      }
    },
    localVisible(newValue) {
      this.$emit("update:isVisible", newValue);
    },
  },
  methods: {

    // 保存表格结构
    saveTableStructure() {
      // 获取光标所在的表格结构
      const table = this.table;
      if (table) {
        const normalizedTable = this.normalizeTable(table); // 这个有问题，要和获取的统一
        // 3. 构造数据对象
        const data = {
          temp_id: this.documentInfo.nodeId,
          formula_id: this.formulaData.id,
          table_content: normalizedTable,
        };

        console.log("========data=========");
        console.log(data);
        console.log(this.documentInfo);

        // 4. 发起POST请求保存表格结构
        axios.post(`${process.env.VUE_APP_HOST}/api/tables/add`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          }
        })
            .then(response => {
              if (response.data.code === 1) {
                this.$message.success('表格结构保存成功');
                this.closeDialog();
              } else {
                this.$message.error('保存失败');
              }
            })
            .catch(error => {
              console.error("保存失败:", error);
              this.$message.error('保存失败');
            });
      } else {
        this.$message.error('没有找到有效的表格');
      }
    },

    // 特征化表格内容：简化表格，去除具体文本内容，只保留结构

    // 计算结果 start
    calculateResult() {
      let formula = this.formulaData.expression;

      // 创建一个包含所有非 result 类型的变量和值的上下文对象
      const context = {};

      // 过滤掉 type 为 'result' 的变量，保留可用的输入变量
      this.formulaVariables.forEach(variable => {
        console.log("=====variable=====");
        console.log(variable.type);
        console.log(variable.name);
        if (variable.type !== 'result') {
          context[variable.name] = variable.value; // 将有效变量添加到上下文
        }
      });
      console.log("=====context=====");
      console.log(context);

      // 去掉公式中的等号
      formula = formula.replace(/=/, ''); // 去掉公式中的等号

      // 打印去掉等号后的公式
      console.log("处理后的公式：", formula);

      // 打印上下文对象，查看所有变量的值
      console.log("公式变量上下文：", context);

      // 使用正则表达式，替换公式中的变量名为上下文中的对应值
      const finalFormula = formula.replace(/[a-zA-Z_][a-zA-Z0-9_]*/g, (match) => {
        // 只替换上下文中存在的有效变量值，忽略 type 为 'result' 的变量
        if (context[match] !== undefined) {
          return context[match]; // 用 context 中的变量值替换公式中的变量名
        }
        return ''; // 如果上下文中没有该变量，删除公式中的该变量
      });

      // 打印替换后的公式
      console.log("替换后的公式：", finalFormula);

      try {
        const result = eval(finalFormula);
        console.log("计算结果：", result); // 打印计算结果

        // 将计算结果赋值给结果变量
        this.formulaVariables.forEach(variable => {
          if (variable.type === 'result') {
            variable.value = result; // 将计算结果赋值给 result 类型的变量
          }
        });

        // 弹出提示显示结果
        this.mathResult = result;
        this.$message.success(`计算结果: ${result}`);
      } catch (error) {
        // 错误处理
        this.$message.error("公式计算失败！");
        console.error("计算公式时出错：", error);
      }
    },

    // 计算结果 end
    async fetchFormulaVariables(formula) {
      try {
        const response = await axios.post(
            `${process.env.VUE_APP_HOST}/api/template_formula/details`,
            { formulaId: formula.id },
            { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
        );

        if (response.data.code === 1) {
          // 提取返回的公式变量数据
          this.formulaVariables = response.data.data.data.map(item => ({
            id: item.id,
            name: item.name,
            description: item.description,
            type: item.type,
            default_value: item.default_value,  // 初始值
            value: item.default_value,  // 用于绑定用户输入的值
          }));
        } else {
          this.$message.error(`加载公式变量失败: ${response.data.msg}`);
        }
      } catch (error) {
        console.error(error);
        this.$message.error("加载公式变量失败！");
      }
    },
    // 在这里把绑定的公式进行循环查找，找到对应的特征符的内容
    async fetchRelatedFormulas() {
      try {
        this.selectedFormulas = [];
        const response = await axios.post(
            `${process.env.VUE_APP_HOST}/api/template_formula/targetformulas`,
            { template_id: this.documentInfo.nodeId },
            { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
        );
        if (response.data.code === 1) {
          this.selectedFormulas = response.data.data.data.map((item) => ({
            id: item.id,
            name: item.name,
            description: item.description,
            expression: item.expression,
            isRelated: item.is_related,
          }));
          // 循环this.selectedFormulas
          for (let formula of this.selectedFormulas) {
            await this.fetchTableData(formula.id, this.documentInfo.nodeId);  // 调用 getTable 接口获取表格数据
          }

        } else {
          this.$message.error(`加载公式失败: ${response.data.msg}`);
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 获得是否有关联表格的内容
    async fetchTableData(formulaId, template_id) {
      try {
        const response = await axios.post(
            `${process.env.VUE_APP_HOST}/api/tables/getTable`,
            { formula_id: formulaId,temp_id:template_id},  // 这里将 formulaId 作为 temp_id
            { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
        );

        if (response.data.code === 1) {
          const tableContent = response.data.data.content;

          const normalizedTableContent = this.converHtmltoTable(this.originalHtml);
          const isMatching = this.compareTables(normalizedTableContent, tableContent);


          if (isMatching > 0) {
            const tables = this.extractTablesFromHtml(this.originalHtml);
            const matchedTable = tables[isMatching - 1];  // 获取匹配的表格
            matchedTable.style.border = '3px solid red';  // 为表格添加高亮样式
            console.log("======test 88==========");
            console.log(matchedTable);
            this.originalHtml = this.updateTableInHtml(this.originalHtml, matchedTable, isMatching);
            const editableContainer = this.$refs.editableContent;
            if (editableContainer) {
              editableContainer.innerHTML = this.originalHtml;
            }
          }



        }
      } catch (error) {
        console.error(error);
      }
    },
    // 更新HTML中的表格内容
    updateTableInHtml(html, matchedTable, tableIndex) {
      // 创建一个虚拟的 DOM 元素来处理 HTML 字符串
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');

      // 获取所有的 <table> 元素
      const tables = Array.from(doc.querySelectorAll('table'));

      console.log("找到的所有表格:", tables);

      // 检查 tables 是否为空
      if (tables.length === 0) {
        console.error("No tables found in the HTML");
        return html;  // 如果没有表格，直接返回原始HTML
      }
      // 将匹配的表格替换为新的表格
      tables[tableIndex - 1].outerHTML = matchedTable.outerHTML;

      // console.log("更新后的HTML:", doc.documentElement.outerHTML);

      // 返回更新后的 HTML 字符串
      return doc.documentElement.outerHTML;
    },

    // 从HTML中提取所有的表格
    extractTablesFromHtml(html) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');
      return doc.querySelectorAll('table');  // 获取所有table元素
    },

    compareTables(normalizedTableContent, table) {
      const tableContent = JSON.parse(table);
      for (let key in normalizedTableContent) {
        if (Object.prototype.hasOwnProperty.call(normalizedTableContent, key)) {
          const table = normalizedTableContent[key];
          console.log("==table");
          console.log(table);
          console.log("==ttableContent");
          console.log(tableContent);

          const isColumnsEqual = table.columns === tableContent.columns;
          const isColspanCountEqual = table.colspanCount === tableContent.colspanCount;
          const isRowspanCountEqual = table.rowspanCount === tableContent.rowspanCount;
          const isHeaderEqual = JSON.stringify(table.header) === JSON.stringify(tableContent.header);
          console.log(isColumnsEqual);
          console.log(isColspanCountEqual);
          console.log(isRowspanCountEqual);
          console.log(isHeaderEqual);
          if (isColumnsEqual && isColspanCountEqual && isRowspanCountEqual && isHeaderEqual) {
            return key;
          }
        }
      }
      return null;
    },
    // 向数据库中保存特征结构的方法
    normalizeTable(tableElement) {

      const header = [];
      const bodyRows = tableElement.querySelectorAll("tbody tr");
      let totalColumns = 0;
      let colspanCount = 0;
      let rowspanCount = 0;

      // 处理 tbody 中的每一行
      bodyRows.forEach((row) => {
        let columnsInRow = 0;
        row.querySelectorAll("td, th").forEach((cell) => {
          const colspan = parseInt(cell.getAttribute("colspan") || 1, 10);
          const rowspan = parseInt(cell.getAttribute("rowspan") || 1, 10);

          columnsInRow += colspan; // 计算当前行总列数
          colspanCount += colspan - 1; // 计算 colspan 总数
          rowspanCount += rowspan - 1; // 计算 rowspan 总数
        });
        totalColumns = Math.max(totalColumns, columnsInRow); // 更新最大列数
      });

      // 处理表头行（从 <thead> 或第一个 <tbody> 的 <tr> 中提取）
      const firstRow = tableElement.querySelector("thead tr") || tableElement.querySelector("tbody tr");
      if (firstRow) {
        firstRow.querySelectorAll("th, td").forEach((cell) => {
          header.push(cell.textContent.trim()); // 提取表头内容
        });
      }

      // 将提取的内容结构化为特征对象
      const result = {
        columns: totalColumns, // 总列数
        colspanCount,          // 总 colspan 数
        rowspanCount,          // 总 rowspan 数
        header,                // 表头内容
      };

      return JSON.stringify(result);
    },
    // 将html 转成 html对象
    converHtmltoTable(tableHtml) {
      if (!tableHtml) return null;

      // 将 HTML 字符串解析为 DOM
      const parser = new DOMParser();
      const doc = parser.parseFromString(tableHtml, "text/html");
      const tableElements = doc.querySelectorAll("table");

      if (!tableElements.length) return null; // If no tables are found

      const result = {};

      // 遍历所有 table 元素
      tableElements.forEach((tableElement, index) => {
        const header = [];
        const bodyRows = tableElement.querySelectorAll("tbody tr");
        let totalColumns = 0;
        let colspanCount = 0;
        let rowspanCount = 0;

        // 处理 tbody 中的每一行
        bodyRows.forEach((row) => {
          let columnsInRow = 0;
          row.querySelectorAll("td, th").forEach((cell) => {
            const colspan = parseInt(cell.getAttribute("colspan") || 1, 10);
            const rowspan = parseInt(cell.getAttribute("rowspan") || 1, 10);

            columnsInRow += colspan; // 计算当前行总列数
            colspanCount += colspan - 1; // 计算 colspan 总数
            rowspanCount += rowspan - 1; // 计算 rowspan 总数
          });
          totalColumns = Math.max(totalColumns, columnsInRow); // 更新最大列数
        });

        // 处理表头行（从 <thead> 或第一个 <tbody> 的 <tr> 中提取）
        const firstRow = tableElement.querySelector("thead tr") || tableElement.querySelector("tbody tr");
        if (firstRow) {
          firstRow.querySelectorAll("th, td").forEach((cell) => {
            header.push(cell.textContent.trim()); // 提取表头内容
          });
        }

        // 将提取的内容结构化为特征对象
        const resultContent = {
          columns: totalColumns, // 总列数
          colspanCount,          // 总 colspan 数
          rowspanCount,          // 总 rowspan 数
          header,                // 表头内容
        };

        // 存储每个表格的特征信息，使用唯一的 key（table_1、table_2 等）
        result[`${index + 1}`] = resultContent;
      });

      // 返回包含所有表格特征信息的对象
      return result;
    },

    handleFormulaSelected(formula) {
      const context = this.getCursorContext();
      if (!context || !context.row) {
        this.$message.error("请将光标放在表格内！");
        return;
      }
      this.cursorContext = context;
      this.formulaVariables = this.getRowData(context.row);

      this.formulaData = {
        id: formula.id,
        name: formula.name,
        expression: formula.expression,
        selectedVariable: [],
      };
      this.isFormulaDialogVisible = true;
      // 调用方法加载公式变量
      this.fetchFormulaVariables(formula);

    },
    closeFormulaDialog() {
      this.isFormulaDialogVisible = false;
    },
    applyFormula() {
      if (!this.cursorContext || !this.cursorContext.cell) {
        this.$message.error("光标上下文丢失，无法应用公式！");
        return;
      }
      const contextCell = this.cursorContext.cell;
      contextCell.innerHTML = '<span style="font-family:仿宋">'+this.mathResult+'</span>';
      this.updateContent();
      this.isFormulaDialogVisible = false;
    },
    getCursorContext() {
      this.table = "";
      const selection = window.getSelection();
      if (!selection.rangeCount) return null;
      const range = selection.getRangeAt(0);
      const container = range.commonAncestorContainer;
      const cell = container.nodeType === 3 ? container.parentElement.closest("td, th") : container.closest("td, th");
      if (!cell) return null;

      const row = cell.parentElement;
      const table = row.parentElement; // 获取表格的父容器（通常是 <tbody>）
      // 获取 table 的结构
      this.table = table.parentElement;
      // 获取行数据
      const rowData = this.getRowData(row);

      // 获取列数据
      const cellIndex = Array.from(row.children).indexOf(cell);
      const columnData = this.getColumnData(table, cellIndex);
      // 将行和列数据存储到变量中，并过滤空值
// 将行和列数据存储到变量中，并过滤空值和非数字
// 将行和列数据存储到变量中，并过滤空值和非数字
      this.dropdownOptions = [
        ...rowData
            .filter(data => !isNaN(parseFloat(data)) && parseFloat(data) !== '' && data.trim() !== '') // 过滤非数字和空值
            .map((data, idx) => ({
              label: `行数据: ${data}`,
              value: data,
            })),
        ...columnData
            .filter(data => !isNaN(parseFloat(data)) && parseFloat(data) !== '' && data.trim() !== '') // 过滤非数字和空值
            .map((data, idx) => ({
              label: `列数据: ${data}`,
              value: data,
            })),
      ];



      return { row, cell, rowData, columnData };
    },
    // 获取行数据
    getRowData(row) {
      return Array.from(row.children).map(cell => cell ? cell.textContent.trim() : '');
    },

    // 获取列数据（考虑合并单元格的情况）
    getColumnData(table, cellIndex) {
      return Array.from(table.rows).map((row, index) => {
        const cell = row.cells[cellIndex];
        if (cell && cell.rowSpan > 1) {
          // 如果是合并单元格，跳过合并区域的单元格
          return index === 0 ? cell.textContent.trim() : '';
        }
        return cell ? cell.textContent.trim() : '';
      });
    },

    // 获得编辑文档的内容
    async loadHtmlContent() {
      this.originalHtml = "<p>正在加载文档</p>";
      try {
        const response = await axios.post(
            `${process.env.VUE_APP_HOST}/api/handlecontent/docxTohtml`,
            { path: this.fileData.data },
            { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
        );
        if (response.data?.data) {
          this.originalHtml = response.data.data;
          this.$nextTick(() => {
            const editableContainer = this.$refs.editableContent;
            if (editableContainer) {
              editableContainer.innerHTML = this.originalHtml;
              this.fetchRelatedFormulas();
            }
          });
        } else {
          this.originalHtml = "<p>加载失败！</p>";
        }
      } catch (error) {
        console.error(error);
        this.originalHtml = "<p>无法加载内容，请稍后重试。</p>";
      }
    },
    updateContent() {
      const editableContainer = this.$refs.editableContent;
      if (editableContainer) {
        window.requestAnimationFrame(() => {
          this.originalHtml = editableContainer.innerHTML;
        });
      }
    },
    // 将编辑的内容转成docx
    saveEditedContent() {
      const payload = {
        fileData: JSON.stringify(this.fileData),
        htmlContent: this.originalHtml,
        formulas: this.selectedFormulas,
      };
      axios
          .post(`${process.env.VUE_APP_HOST}/api/handlecontent/htmlTodocx`, payload, {
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
          })
          .then((response) => {
            if (response.data.code === 1) {
              this.$message.success("保存成功！");
            } else {
              this.$message.error(response.data.msg || "保存失败！");
            }
          })
          .catch((error) => {
            this.$message.error("保存请求失败！");
          })
          .finally(() => {
            this.closeDialog();
          });
    },
    closeDialog() {
      this.localVisible = false;
      this.originalHtml = "";
    },
  },
};
</script>

<style scoped>
.table-editor-layout {
  display: flex;
  gap: 20px;
}

.html-editor-section {
  flex: 2;
}

.formula-list-section {
  flex: 1;
}

.html-editable-container {
  outline: none;
  border: none;
  padding: 10px;
  height: 300px;
  background-color: #f9f9f9; /* 确保这里的背景色正确 */
  overflow: auto; /* 如果内容超出，则出现滚动条 */
}

.loading-container {
  text-align: center;
  padding: 20px;
}

.formula-list-section h4 {
  margin-bottom: 10px;
}
.formula-editor-container {
  padding: 20px;
}

.el-input[readonly] {
  background-color: #f5f5f5;
  cursor: not-allowed;
}
.formula-editor-container {
  padding: 20px;
}

.el-input[readonly] {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.variable-name {
  flex: 1; /* 让变量名占据剩余空间 */
}

.variable-row {
  margin-bottom: 20px; /* 每行间距 */
  display: flex; /* 垂直排列 */
  width: 100%;
}

.variable-name {
  margin-bottom: 5px; /* 常量名称与下拉框之间的间距 */
}

.input-row {
  width: 88%;
}



</style>
