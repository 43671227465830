<template>
  <el-dialog
      v-model="localVisible"
      title="设置计算公式"
      width="50%"
      :before-close="closeDialog"
  >
    <el-form>
      <!-- 遍历所有变量，允许用户绑定 -->
      <el-form-item
          v-for="(varLabel, index) in Object.keys(variableMapping)"
          :key="index"
          :label="`绑定 ${varLabel}`"
      >
        <el-select
            v-model="variableMapping[varLabel]"
            placeholder="选择变量"
        >
          <el-option
              v-for="(variable, idx) in variables"
              :key="idx"
              :label="variable.label"
              :value="variable.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <!-- 用户输入公式 -->
      <el-form-item label="公式">
        <el-input
            v-model="formula"
            placeholder="例如: A * B / 1000"
        ></el-input>
      </el-form-item>
    </el-form>

    <!-- 弹窗底部按钮 -->
    <template #footer>
      <el-button @click="closeDialog">取消</el-button>
      <el-button
          type="primary"
          :disabled="!isFormulaValid()"
          @click="applyFormula"
      >
        应用
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: "FormulaEditor",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    variables: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      localVisible: this.visible,
      variableMapping: {
        A: null,
        B: null,
        // 可扩展更多变量
      },
      formula: "",
    };
  },
  watch: {
    visible(newValue) {
      this.localVisible = newValue;
    },
    localVisible(newValue) {
      this.$emit("update:visible", newValue);
    },
  },
  methods: {
    // 校验公式是否有效
    isFormulaValid() {
      return (
          this.formula.trim() !== "" &&
          Object.values(this.variableMapping).every((value) => value !== null)
      );
    },

    // 应用公式并计算结果
    applyFormula() {
      try {
        // 替换公式中的变量名为对应值
        const parsedFormula = this.formula.replace(/\b[A-Z]\b/g, (varName) => {
          const mappedValue = this.variableMapping[varName];
          return mappedValue !== null ? mappedValue : "0"; // 未绑定变量默认为 0
        });

        // 计算公式结果
        const result = eval(parsedFormula);

        // 将结果回传给父组件
        this.$emit("formulaApplied", {
          formula: this.formula,
          mapping: this.variableMapping,
          result: result.toFixed(6),
        });

        this.closeDialog();
      } catch (error) {
        this.$message.error("公式计算失败，请检查公式！");
      }
    },

    // 关闭弹窗
    closeDialog() {
      this.localVisible = false;
    },
  },
};
</script>

<style scoped>
.el-dialog {
  padding: 20px;
}
</style>
