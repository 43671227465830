<template>
  <el-header class="header">
    <div class="logo">
      <span>有色金属行业企业温室气体排放核算系统</span>
    </div>
    <div class="user-info">
      <el-dropdown>
        <span class="user-name">
          您好, {{ userInfo.username }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
<!--            <el-dropdown-item @click="changePassword">修改密码</el-dropdown-item>-->
            <el-dropdown-item @click="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <span class="user-company">
        用户所属: {{ userInfo.compay }}
      </span>
    </div>
  </el-header>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';

const router = useRouter();
const userInfo = ref({ username: '', compay: '' });

const fetchUserInfo = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${process.env.VUE_APP_HOST}/api/user/getUserInfo`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (response.data && response.data.code === 1) {
      userInfo.value = response.data.data;
    } else {
      console.error('获取用户信息失败:', response.data.msg);
    }
  } catch (error) {
    console.error('请求用户信息时发生错误:', error);
  }
};

const changePassword = () => {
  console.log('修改密码功能触发');
};

const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('isLoggedIn');
  router.push('/login');
};

onMounted(() => {
  fetchUserInfo();
});
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: var(--primary-color);
  color: white;
  width: 100%;
}

.logo {
  display: flex;
  align-items: center;
}

.user-info {
  display: flex;
  align-items: center;
  color: var(--white-color);
}

.user-name {
  margin: 0 15px;
  color: var(--white-color);
  font-size: 16px;
  font-weight: normal;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}

.user-company {
  margin-left: 15px;
  color: var(--white-color);
  font-size: 16px;
}
</style>
