<template>
  <div class="data-center-option">
    <!-- 顶部按钮 -->
    <div class="button-container">
      <el-button type="primary" class="add-report-button" @click="openAddDialog">
        <el-icon class="button-icon">
          <CirclePlus />
        </el-icon>
        <span class="button-text">新增填报</span>
      </el-button>
    </div>
    <el-dialog title="编辑填报" v-model="showEditDialog" width="500px">
      <el-form :model="editReport" label-width="120px" ref="editReportForm">
        <el-form-item
            label="受核查单位"
            prop="companyName"
            :rules="[{ required: true, message: '请选择受核查单位', trigger: 'change' }]">
          <el-select
              v-model="editReport.companyName"
              placeholder="请选择受核查单位">
            <el-option
                v-for="company in companies"
                :key="company.id"
                :label="company.companyName"
                :value="company.companyName"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="核查机构" prop="verificationAgency" :rules="[{ required: true, message: '请输入核查机构', trigger: 'blur' }]">
          <el-input v-model="editReport.verificationAgency" placeholder="请输入核查机构"></el-input>
        </el-form-item>
        <el-form-item label="模板" prop="temp_id" :rules="[{ required: true, message: '请选择模板', trigger: 'change' }]">
          <el-select v-model="editReport.temp_id" placeholder="请选择模板">
            <el-option
                v-for="category in topCategories"
                :key="category.id"
                :label="category.label"
                :value="category.id"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="showEditDialog = false">取消</el-button>
        <el-button type="primary" @click="onEditSubmit">保存</el-button>
      </template>
    </el-dialog>

    <!-- 新增填报对话框 -->
    <el-dialog title="新增填报" v-model="showAddDialog" width="500px">
      <el-form :model="newReport" label-width="120px" ref="reportForm">
        <el-form-item
            label="受核查单位"
            prop="companyName"
            :rules="[{ required: true, message: '请选择受核查单位', trigger: 'change' }]">
          <el-select
              v-model="newReport.companyName"
              placeholder="请选择受核查单位">
            <el-option
                v-for="company in companies"
                :key="company.id"
                :label="company.companyName"
                :value="company.companyName"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="核查机构" prop="verificationAgency" :rules="[{ required: true, message: '请输入核查机构', trigger: 'blur' }]">
          <el-input v-model="newReport.verificationAgency" placeholder="请输入核查机构"></el-input>
        </el-form-item>
        <el-form-item label="模板" prop="temp_id" :rules="[{ required: true, message: '请选择模板', trigger: 'blur' }]">
          <el-select v-model="newReport.temp_id" placeholder="请选择模板">
            <el-option v-for="category in topCategories" :key="category.id" :label="category.label" :value="category.id" />
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="showAddDialog = false">取消</el-button>
        <el-button type="primary" @click="onSubmit">添加</el-button>
      </template>
    </el-dialog>

    <!-- 数据表 -->
    <el-table :data="paginatedData" border style="width: 100%;" stripe>
      <el-table-column prop="companyName" label="受核查单位" />
      <el-table-column prop="verificationAgency" label="核查机构" />
      <el-table-column prop="templateName" label="模板名称" />

      <!-- 操作列 -->
      <el-table-column label="操作" width="180">
        <template #default="{ row }">
          <div class="action-buttons">
            <el-button type="primary" size="small" @click="openEditDialog(row)">编辑</el-button>
            <el-button type="primary" size="small" @click="towrite(row)">去填报</el-button>
            <el-button type="danger" size="small" @click="deleteRow(row)">删除</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
        style="margin-top: 20px; text-align: right;"
        background
        layout="prev, pager, next"
        :total="tableData.length"
        :page-size="pageSize"
        v-model:currentPage="currentPage"
        @current-change="handlePageChange"
    />
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { ElMessage, ElMessageBox } from 'element-plus';
import { CirclePlus } from '@element-plus/icons-vue';
import { useRouter } from 'vue-router';
const router = useRouter();
const userInfo = ref({ username: '', compay: '' });

export default {
  name: "ReportCenter",
  components: {
    CirclePlus,
  },
  data() {
    return {
      companies: [], // 公司列表
      showEditDialog: false,
      editReport: {
        id: null, // 编辑记录的ID
        companyName: '',
        verificationAgency: '',
        temp_id: '',
      },
      topCategories: [], // 一级分类数据
      templateMap: {}, // 模板ID与名称映射缓存
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      showAddDialog: false,
      newReport: {
        companyName: '',
        verificationAgency: '',
        temp_id: '', // 用户选择的模板 ID
      },
    };
  },
  computed: {
    paginatedData() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = this.currentPage * this.pageSize;
      return this.tableData.slice(start, end).map((item) => ({
        ...item,
        templateName: this.templateMap[item.temp_id] || "未定义模板",
      }));
    },
  },
  methods: {
    async fetchCompanies() {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.post(`${process.env.VUE_APP_HOST}/api/company/list`, {}, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data && response.data.code === 1) {
          // 提取嵌套的公司数据
          this.companies = response.data.data.data || [];
        } else {
          this.$message.error(response.data.msg || "获取公司列表失败");
        }
      } catch (error) {
        this.$message.error("获取公司列表请求失败");
      }
    },
    openEditDialog(row) {
      this.editReport = { ...row }; // 填充编辑表单
      this.showEditDialog = true;
      this.fetchCompanies();
    },
    async onEditSubmit() {
      this.$refs.editReportForm.validate(async (valid) => {
        if (valid) {
          try {
            const token = localStorage.getItem('token');
            const response = await axios.post(`${process.env.VUE_APP_HOST}/api/reports/edit`, this.editReport, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });

            if (response.data && response.data.code === 1) {
              this.$message.success('编辑成功');
              this.showEditDialog = false;
              this.fetchData(); // 刷新列表数据
            } else {
              this.$message.error(response.data.msg || '编辑失败');
            }
          } catch (error) {
            this.$message.error('编辑请求失败');
          }
        }
      });
    },
    async fetchTopCategories() {
      const router = useRouter(); // 将 useRouter 移动到方法内部
      try {
        const token = localStorage.getItem('token');
        const response = await axios.post(`${process.env.VUE_APP_HOST}/api/documents/getTopCategories`, {}, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data && response.data.code === 1) {
          this.topCategories = response.data.data.categories;
          // 更新模板映射缓存
          response.data.data.categories.forEach(category => {
            this.templateMap[category.id] = category.label;
          });
        } else {
          ElMessage.error(response.data.msg || "获取一级分类失败");
          localStorage.removeItem('token');
          localStorage.removeItem('isLoggedIn');
          router.push('/login');
        }
      } catch (error) {
        ElMessage.error("获取一级分类请求失败");
        localStorage.removeItem('token');
        localStorage.removeItem('isLoggedIn');
        router.push('/login');
      }
    },
    async fetchData() {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.post(`${process.env.VUE_APP_HOST}/api/reports/list`, {}, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data && response.data.code === 1) {
          this.tableData = response.data.data.data;
        } else {
          ElMessage.error(response.data.msg || "获取数据失败");
        }
      } catch (error) {
        ElMessage.error("获取数据请求失败");
      }
    },
    openAddDialog() {
      if (!this.topCategories.length) {
        this.fetchTopCategories(); // 加载模板分类
      }

      if (!this.companies.length) {
        this.fetchCompanies(); // 加载公司列表
      }
      this.showAddDialog = true; // 显示新增对话框
    },
    async addReport() {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.post(`${process.env.VUE_APP_HOST}/api/reports/add`, this.newReport, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data && response.data.code === 1) {
          ElMessage.success("报告添加成功");
          this.showAddDialog = false; // 关闭弹窗
          this.fetchData(); // 刷新数据
          this.resetForm();
        } else {
          ElMessage.error(response.data.msg || "添加失败");
        }
      } catch (error) {
        ElMessage.error("添加请求失败");
      }
    },
    onSubmit() {
      this.$refs.reportForm.validate((valid) => {
        if (valid) {
          this.addReport();
        } else {
          ElMessage.error("请完善表单信息");
          return false;
        }
      });
    },
    resetForm() {
      this.newReport = { companyName: '', verificationAgency: '', temp_id: '' };
    },
    handlePageChange(page) {
      this.currentPage = page;
    },
    towrite(row) {
      const basePath = window.location.href.split('#')[0];
      const url = `${basePath}#/edit-report/${row.id}/${row.temp_id}`;
      window.open(url, '_blank');
    },
    async deleteRow(row) {
      try {
        await ElMessageBox.confirm('确定删除这条记录吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        });

        const token = localStorage.getItem('token');
        const response = await axios.post(`${process.env.VUE_APP_HOST}/api/reports/delete`, { id: row.id }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data && response.data.code === 1) {
          this.tableData = this.tableData.filter(item => item.id !== row.id);
          ElMessage.success('删除成功');
        } else {
          ElMessage.error(response.data.msg || '删除失败');
        }
      } catch (error) {
        if (error !== 'cancel') {
          ElMessage.error('删除请求失败');
        }
      }
    },
  },
  mounted() {
    this.fetchData(); // 获取表格数据
    this.fetchTopCategories(); // 初始化一级分类缓存
  },
};
</script>

<style scoped>
.data-center-option {
  padding: 20px;
  background-color: var(--background-color);
}

.button-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.add-report-button {
  background-color: var(--primary-color);
  color: var(--white-color);
  border: none;
}

.add-report-button:hover {
  background-color: var(--secondary-color);
  color: var(--white-color);
}

.el-table .el-table__header-wrapper th {
  background-color: var(--lightgray-color);
  color: var(--text-color);
  font-weight: bold;
  text-align: center;
}

.el-table th,
.el-table td {
  text-align: center;
  color: var(--text-color);
}

.action-buttons {
  display: flex;
  gap: 5px;
}

.el-dialog__footer {
  text-align: right;
}
</style>
