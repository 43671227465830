<template>
  <div class="document-management">
    <h2>文档管理</h2>
    <el-table :data="documents" stripe style="width: 100%">
      <el-table-column prop="nodeName" label="节点名"/>
      <el-table-column prop="originalFileName" label="原文件名"/>
      <el-table-column prop="created_at" label="上传日期"/>

      <el-table-column label="操作">
        <template #default="scope">
          <el-button type="text" @click="downloadFile(scope.row)">下载</el-button>
          <el-button type="text" @click="deleteFile(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import axios from 'axios';
import { ElMessage } from 'element-plus';

export default {
  data() {
    return {
      documents: [] // 存储文件列表
    };
  },
  methods: {
    // 获取文件列表
    async fetchDocuments() {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${process.env.VUE_APP_HOST}/api/documents/listTemplates`, {
          headers: { Authorization: `Bearer ${token}` }
        });

        // 正确解析接口返回的数据结构
        if (response.data && response.data.code === 1 && Array.isArray(response.data.data.data)) {
          this.documents = response.data.data.data; // 解析到嵌套的 data 数组
        } else {
          console.error("获取模板列表失败:", response.data.msg);
        }
      } catch (error) {
        console.error("获取模板列表出错:", error);
      }
    },

    // 下载文件
    async downloadFile(file) {
      try {
        const token = localStorage.getItem('token');
        const url = `${process.env.VUE_APP_HOST}/api/documents/downloadTemplate`;

        // 使用 axios 发送 POST 请求，传递 id 参数，并包含 Authorization 头部
        const response = await axios.post(url, { id: file.id }, {
          headers: { Authorization: `Bearer ${token}` },
          responseType: 'blob' // 指定响应类型为 blob，适合文件下载
        });

        // 创建一个链接元素，用于触发下载
        const blob = new Blob([response.data]);
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = file.name; // 设置下载的文件名
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // 清理 DOM
        window.URL.revokeObjectURL(downloadUrl); // 释放 blob URL

      } catch (error) {
        console.error("文件下载失败:", error);
        ElMessage.error("文件下载失败");
      }
    },

    // 删除文件
    async deleteFile(file) {
      try {
        const token = localStorage.getItem('token');
        await axios.post(
            `${process.env.VUE_APP_HOST}/api/documents/deleteTemplate`,
            { id: file.id },
            {
              headers: {
                Authorization: `Bearer ${token}`
              }
            }
        );
        ElMessage.success("文件已删除");
        this.fetchDocuments(); // 刷新文件列表
      } catch (error) {
        ElMessage.error("删除文件失败");
      }
    }
  },
  mounted() {
    this.fetchDocuments();
  }
};
</script>

<style>
.document-management {
  padding: 20px;
}
</style>
