<template>
  <div class="data-center-option">
    <!-- 新增大类按钮 -->
    <div class="button-container">
      <el-button type="primary" class="add-category-button" @click="addCategory">
        <el-icon class="button-icon">
          <CirclePlus />
        </el-icon>
        <span class="button-text">新增大类</span>
      </el-button>
    </div>

    <!-- 多级菜单目录 -->
    <div class="directory-container">
      <el-tree
          ref="directoryTree"
          :data="directoryData"
          :props="defaultProps"
          :default-expanded-keys="expandedKeys"
          node-key="id"
          highlight-current
          expand-on-click-node="false"
          @node-click="handleNodeClick"
          class="directory-tree"
      >
        <!-- 自定义节点 -->
        <template #default="{ node, data }">
          <div :class="['tree-node', { 'root-node': node.level === 1 }]">
            <span
                class="expand-icon"
                @click.stop="toggleNode(node)"
            >
              <i :class="node.expanded ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"></i>
            </span>
            <span class="node-label">{{ data.label }}</span>
            <span v-if="node.level !== 1" class="leader-dots"></span>
            <div v-if="node.level === 1" class="action-buttons">
              <el-button style="color:red"
                  type="text"
                  size="mini"
                  @click.stop="importDefaultContents(data)"
              >
                引入现有目录
              </el-button>
              <el-button type="text" size="mini" @click.stop="deleteCategory(data)">删除</el-button>
            </div>
            <div v-if="node.level !== 1" class="action-buttons">
              <div class="action-buttons">
                <el-dropdown class="dropdown-action" v-if="data.hasUploadedTemplate">
                  <el-button type="text" size="mini">
                    操作模板
                  </el-button>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item v-if="data.hasUploadedTemplate">
                        文件名：{{ data.originalFileName }}
                      </el-dropdown-item>
                      <el-dropdown-item @click="deleteTemplate(data.id)">
                        删除模板
                      </el-dropdown-item>
                      <el-dropdown-item  @click="downloadTemplate(data.id)">
                        下载模板
                      </el-dropdown-item>
                      <el-dropdown-item  @click="editTemplate(data.id)">
                        编辑模板
                      </el-dropdown-item>
                      <el-dropdown-item @click="openFormulaDialog(data.id)">
                        关联公式
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
                <el-button
                    type="text"
                    size="mini"
                    class="upload-button"
                    @click.stop="openUploadDialog(data)"
                >
                  上传模板
                </el-button>
              </div>

              <el-button type="text" size="mini" @click.stop="addSubDirectory(data)">新增子目录</el-button>
              <el-button type="text" size="mini" @click.stop="editNode(data)">编辑名称</el-button>
              <el-button type="text" size="mini" @click.stop="deleteNode(data)">删除目录</el-button>
            </div>
          </div>
        </template>
      </el-tree>
    </div>

  <!--关联公式-->
    <el-dialog
        :key="formulaDialogKey"
        title="关联公式"
        v-model="showFormulaDialog"
        width="600px"
        @close="closeFormulaDialog"
    >
      <!-- 表格展示公式列表 -->
      <el-table
          :data="formulaList"
          border
          style="width: 100%"
          :row-key="row => row.id"
          @selection-change="handleFormulaSelection"
          ref="formulaTable"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column prop="name" label="公式名称" width="180" />
        <el-table-column prop="description" label="描述" width="300" />
        <el-table-column prop="expression" label="公式表达式" width="300" />
      </el-table>
      <template #footer>
        <el-button @click="closeFormulaDialog">取消</el-button>
        <el-button type="primary" @click="saveFormulaAssociations">保存</el-button>
      </template>
    </el-dialog>



    <!--关联公式-->
    <!-- 新增大类对话框 -->
    <el-dialog title="新增大类" v-model="showAddCategoryDialog" width="400px">
      <el-form :model="newCategory" label-width="80px">
        <el-form-item label="目录名称">
          <el-input v-model="newCategory.label" placeholder="请输入目录名称"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="showAddCategoryDialog = false" size="small">取消</el-button>
<!--        <el-button :disabled="!isCategoryCreated" type="primary" @click="importDefaultContents" size="small">引入现有目录</el-button>-->
        <el-button type="primary" @click="onSaveNewCategory" size="small">保存</el-button>
      </template>
    </el-dialog>

    <!-- 编辑目录对话框 -->
    <el-dialog title="编辑目录" v-model="showEditDialog" width="400px">
      <el-form :model="currentNode" label-width="80px">
        <el-form-item label="目录名称">
          <el-input v-model="currentNode.label" placeholder="请输入目录名称"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="showEditDialog = false" size="small">取消</el-button>
        <el-button type="primary" @click="onSaveEditNode" size="small">保存</el-button>
      </template>
    </el-dialog>

    <!-- 新增子目录对话框 -->
    <el-dialog title="新增子目录" v-model="showAddSubDirectoryDialog" width="400px">
      <el-form :model="newSubDirectory" label-width="80px">
        <el-form-item label="目录名称">
          <el-input v-model="newSubDirectory.label" placeholder="请输入子目录名称"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="showAddSubDirectoryDialog = false" size="small">取消</el-button>
        <el-button type="primary" @click="onSaveNewSubDirectory" size="small">保存</el-button>
      </template>
    </el-dialog>

    <!-- 上传模板对话框 -->
    <el-dialog title="上传模板" v-model="showUploadDialog" width="400px">
      <el-upload
          :action="''"
          :before-upload="onUploadConfirm"
          :on-change="handleFileChange"
          list-type="text"
      >
        <el-button type="primary">选择文件</el-button>
      </el-upload>
      <template #footer>
        <el-button @click="showUploadDialog = false" size="small">取消</el-button>
        <el-button type="primary" size="small" @click="onUploadConfirm">确认上传</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import { ElMessageBox, ElMessage } from 'element-plus';

export default {
  data() {
    return {
      // 关联公式
      formulaDialogKey: 0, // 动态弹窗唯一标识符
      showFormulaDialog: false, // 控制关联公式弹窗显示
      formulaList: [], // 公式列表数据
      selectedFormulas: [], // 用户选择的公式ID数组
      currentTemplateId: null, // 当前模板ID
      // 关联公式
      directoryData: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      showAddCategoryDialog: false,
      showEditDialog: false,
      showAddSubDirectoryDialog: false,
      showUploadDialog: false,
      isCategoryCreated: false,
      newCategory: { label: '' },
      currentNode: {},
      newSubDirectory: { label: '' },
      currentParentNode: null,
      expandedKeys: [],
      selectedUploadNode: null,
      selectedFile: null
    };
  },
  methods: {
    // 关联公式
    // 打开关联公式弹窗
    openFormulaDialog(templateId) {
      if (!templateId) {
        this.$message.error("模板 ID 不存在");
        return;
      }
      this.currentTemplateId = templateId; // 记录当前模板ID
      this.selectedFormulas = []; // 清空选择项
      this.formulaList = []; // 清空公式列表
      this.showFormulaDialog = true; // 显示弹窗
      this.fetchFormulas(); // 加载公式列表
    },

    // 获取公式列表
    async fetchFormulas() {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.post(
            `${process.env.VUE_APP_HOST}/api/template_formula/list`,
            { template_id: this.currentTemplateId },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
        );

        if (response.data.code === 1) {
          const formulas = Array.isArray(response.data.data.data)
              ? response.data.data.data
              : [];

          this.formulaList = formulas; // 更新表格数据

          // 默认选中关联的公式
          this.$nextTick(() => {
            const relatedFormulas = formulas.filter(f => f.is_related === 1);
            this.$refs.formulaTable?.clearSelection();
            relatedFormulas.forEach(formula => {
              this.$refs.formulaTable?.toggleRowSelection(formula, true);
            });

            // 更新选中的公式 ID 数组
            this.selectedFormulas = relatedFormulas.map(f => f.id);
          });
        } else {
          this.$message.error(`加载公式列表失败: ${response.data.msg}`);
        }
      } catch (error) {
        this.$message.error("公式列表加载异常");
        console.error("fetchFormulas error:", error);
        this.formulaList = [];
      }
    },
    // 处理用户选择的公式
    handleFormulaSelection(selection) {
      // 记录用户选择的公式 ID
      this.selectedFormulas = selection.map(item => item.id);
    },

    // 关闭弹窗
    closeFormulaDialog() {
      this.showFormulaDialog = false;
      this.currentTemplateId = null;
      this.selectedFormulas = [];
      this.formulaList = []; // 清空公式列表
    },

    // 保存公式与模板关联
    async saveFormulaAssociations() {
      if (!this.currentTemplateId) {
        this.$message.error("未选择模板");
        return;
      }
      try {
        const token = localStorage.getItem('token');
        const response = await axios.post(
            `${process.env.VUE_APP_HOST}/api/template_formula/add`,
            {
              template_id: this.currentTemplateId,
              formula_ids: this.selectedFormulas,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
        );

        if (response.data.code === 1) {
          this.$message.success("公式关联保存成功");
          this.closeFormulaDialog(); // 关闭弹窗并清理数据
        } else {
          this.$message.error(`保存关联失败: ${response.data.msg}`);
        }
      } catch (error) {
        this.$message.error("保存关联失败");
        console.error("saveFormulaAssociations error:", error);
      }
    },
    // 关联公式
    // 编辑模板内容
    editTemplate(id) {
      // 构造新窗口的 URL，假设跳转到 `/edit-template/:id`
      const baseUrl = `${window.location.origin}/#/edit-document`;
      const newWindowUrl = `${baseUrl}/${id}`;
      window.open(newWindowUrl, '_blank');
    },

    // 保存编辑的节点名称
    async onSaveEditNode() {
      if (!this.currentNode.label.trim()) {
        this.$message.error('目录名称不能为空');
        return;
      }

      try {
        const token = localStorage.getItem('token');
        const response = await axios.post(
            `${process.env.VUE_APP_HOST}/api/documents/edit`,
            {
              id: this.currentNode.id,
              label: this.currentNode.label,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
        );

        if (response.data && response.data.code === 1) {
          this.$message.success('目录名称修改成功');
          this.showEditDialog = false; // 关闭编辑对话框
          this.fetchData(); // 刷新目录数据
        } else {
          this.$message.error(`修改失败: ${response.data.msg}`);
        }
      } catch (error) {
        this.$message.error('修改失败，请重试');
      }
    },
    // 新增子目录
    async addSubDirectory(data) {
      try {
        const { value: subDirectoryName } = await this.$prompt(
            '请输入子目录名称',
            '新增子目录',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              inputPattern: /\S+/,
              inputErrorMessage: '子目录名称不能为空',
            }
        );

        const token = localStorage.getItem('token');
        const response = await axios.post(
            `${process.env.VUE_APP_HOST}/api/documents/add`,
            {
              label: subDirectoryName,
              parent_id: data.id,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
        );

        if (response.data && response.data.code === 1) {
          this.$message.success('子目录添加成功');
          this.fetchData(); // 刷新数据
        } else {
          this.$message.error(`添加失败: ${response.data.msg}`);
        }
      } catch (error) {
        if (error !== 'cancel') {
          this.$message.error('子目录添加失败，请重试');
        }
      }
    },
    async fetchData() {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${process.env.VUE_APP_HOST}/api/documents/list`, {
          headers: { Authorization: `Bearer ${token}` }
        });

        if (response.data && response.data.code === 1) {
          // 接收包含 hasUploadedTemplate 字段的目录数据
          this.directoryData = response.data.data.data;
          this.setExpandedKeys(this.directoryData);
        } else {
          console.error("Failed to fetch data:", response.data.msg);
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    },
    editNode(data) {
      this.currentNode = { ...data };
      this.isEditing = true;
      this.showEditDialog = true;
    },
    async deleteCategory(data) {
      try {
        await ElMessageBox.confirm('确定删除该大类及其所有内容吗?', '删除确认', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        });

        const token = localStorage.getItem('token');
        await axios.post(`${process.env.VUE_APP_HOST}/api/documents/delete`, {
          id: data.id,
          deleteAll: true // 用于服务器判断是否删除所有子内容
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        ElMessage.success('大类及其所有内容已删除');
        this.fetchData();
      } catch (error) {
        ElMessage.error("删除失败: " + (error.message || "未知错误"));
      }
    },
    async deleteNode(data) {
      try {
        const token = localStorage.getItem('token');
        await ElMessageBox.confirm('确定删除该目录吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        });
        await axios.post(`${process.env.VUE_APP_HOST}/api/documents/delete`,
            { id: data.id },
            { headers: { Authorization: `Bearer ${token}` }}
        );
        ElMessage.success('删除成功');
        this.fetchData();
      } catch (error) {
        ElMessage.error("删除请求失败");
      }
    },
    // 删除模板
    async deleteTemplate(nodeId) {
      try {
        // 确认是否删除模板
        await this.$confirm('确定要删除该模板吗？此操作不可撤销。', '删除模板', {
          confirmButtonText: '删除',
          cancelButtonText: '取消',
          type: 'warning',
        });

        const token = localStorage.getItem('token');
        const response = await axios.post(
            `${process.env.VUE_APP_HOST}/api/documents/deleteNodeTemplate`,
            { nodeId },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
        );

        if (response.data && response.data.code === 1) {
          this.$message.success('模板删除成功');
          this.fetchData(); // 刷新目录树
        } else {
          this.$message.error(`删除失败: ${response.data.msg}`);
        }
      } catch (error) {
        if (error !== 'cancel') {
          this.$message.error('删除模板失败，请稍后重试');
        }
      }
    },
    // 下载模板
    async downloadTemplate(nodeId) {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.post(
            `${process.env.VUE_APP_HOST}/api/documents/getTemplateByNode`,
            { nodeId },
            {
              headers: { Authorization: `Bearer ${token}` }
            }
        );

        if (response.data && response.data.code === 1 && response.data.data.file) {
          const fileUrl = `${process.env.VUE_APP_HOST}${response.data.data.file.path}`;
          const originalFileName = response.data.data.file.originalFileName || '未命名文件.docx';

          // 使用 fetch 下载文件 blob
          const fileResponse = await fetch(fileUrl);
          const blob = await fileResponse.blob();

          // 创建一个临时的 URL 对象
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = originalFileName; // 设置文件名为 originalFileName
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          ElMessage.success(`文件 "${originalFileName}" 下载成功`);
        } else {
          ElMessage.error("获取文件信息失败");
        }
      } catch (error) {
        ElMessage.error("下载失败");
        console.error("下载失败:", error);
      }
    },
    setExpandedKeys(data) {
      const getKeys = (nodes) => {
        let keys = [];
        nodes.forEach(node => {
          keys.push(node.id);
          if (node.children && node.children.length) {
            keys = keys.concat(getKeys(node.children));
          }
        });
        return keys;
      };
      this.expandedKeys = getKeys(data);
    },

    openUploadDialog(node) {
      this.selectedUploadNode = node;
      this.showUploadDialog = true;
    },

    handleFileChange(file) {
      console.log("Selected file:", file);
      this.selectedFile = file.raw;
    },

    async onUploadConfirm() {
      console.log("onUploadConfirm triggered");

      if (!this.selectedUploadNode) {
        ElMessage.error("未选择有效节点进行上传");
        return false;
      }
      if (!this.selectedFile) {
        ElMessage.error("请选择要上传的文件");
        return false;
      }

      // 获取节点名称
      const nodeName = this.selectedUploadNode.label;
      // 获取上传文件的原文件名
      const originalFileName = this.selectedFile.name;

      const formData = new FormData();
      formData.append("file", this.selectedFile);
      formData.append("nodeId", this.selectedUploadNode.id);
      formData.append("nodeName", nodeName);
      formData.append("originalFileName", originalFileName);

      try {
        const url = `${process.env.VUE_APP_HOST}/api/documents/uploadTemplate`;
        const token = localStorage.getItem('token');

        const response = await axios.post(
            url,
            formData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data"
              }
            }
        );

        if (response.data && response.data.code === 1) {
          ElMessage.success(`模板已成功上传到节点: ${this.selectedUploadNode.label}`);
          this.fetchData(); // 刷新数据
        } else {
          ElMessage.error("上传失败：" + response.data.msg);
        }
      } catch (error) {
        console.error("Upload failed:", error);
        ElMessage.error("文件上传失败");
      } finally {
        this.showUploadDialog = false;
        this.selectedFile = null;
        this.selectedUploadNode = null;
      }

      return false;
    },

    addCategory() {
      this.newCategory = { label: '' };
      this.isCategoryCreated = false;
      this.showAddCategoryDialog = true;
    },

    async onSaveNewCategory() {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.post(`${process.env.VUE_APP_HOST}/api/documents/add`, {
          label: this.newCategory.label,
          parent_id: 0
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        if (response.data && response.data.code === 1) {
          this.$message.success("大类创建成功");
          this.showAddCategoryDialog = false; // 关闭弹窗
          this.fetchData(); // 刷新目录列表
        } else {
          this.$message.error("创建大类失败: " + response.data.msg);
        }
      } catch (error) {
        this.$message.error("创建大类失败: " + error.message);
      }
    },

    async importDefaultContents(node = null) {
      try {
        let mainCategoryId = node ? node.id : this.newCategory.id;

        // 检查是否有 mainCategoryId
        if (!mainCategoryId) {
          ElMessage.error("主分类 ID 不存在，无法导入");
          return;
        }

        const token = localStorage.getItem('token');
        const response = await axios.post(
            `${process.env.VUE_APP_HOST}/api/default_contents/importToDocuments`,
            {
              main_category_id: mainCategoryId,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
        );

        if (response.data && response.data.code === 1) {
          ElMessage.success("默认目录已成功引入");
          this.fetchData(); // 刷新数据
        } else {
          ElMessage.error("引入默认目录失败: " + response.data.msg);
        }
      } catch (error) {
        ElMessage.error("引入默认目录失败: " + error.message);
      }
    },

  },
  mounted() {
    this.fetchData();
  }
};
</script>

<style>
.add-category-button {
  background-color: var(--primary-color);
  color: var(--white-color);
  border: none;
}

.add-category-button:hover {
  background-color: var(--secondary-color);
  color: var(--white-color);
}

.create-default-button {
  background-color: var(--primary-color);
  color: var(--white-color);
  border: none;
}

.create-default-button:hover {
  background-color: var(--secondary-color);
  color: var(--white-color);
}

.action-buttons .el-button {
  background: none !important;
  border-color: transparent !important;
  color: var(--el-color-primary) !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.data-center-option {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 0;
  background-color: var(--background-color);
}

.button-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.directory-container {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}

.directory-tree {
  border: 1px solid var(--lightgray-color);
  border-radius: 4px;
}

.el-tree-node__content {
  height: 50px !important;
  display: flex;
  align-items: center;
  padding: 0 10px;
  transition: background-color 0.3s ease;
  border-radius: 4px;
}

.el-tree-node__content:hover {
  background-color: #f5f7fa;
}

.tree-node {
  display: flex;
  align-items: center;
  width: 100%;
}

.expand-icon {
  cursor: pointer;
  margin-right: 5px;
}

.root-node .node-label {
  font-size: 16px;
  color: #000;
  font-weight: bold;
}

.node-label {
  font-size: 14px;
  color: #333;
  font-weight: 500;
  margin-right: auto;
  white-space: nowrap;
}

.leader-dots {
  flex-grow: 1;
  border-bottom: 1px dotted #333;
  margin: 0 10px;
  height: 0;
}

.el-dialog__footer {
  text-align: right;
}

.el-form-item__label {
  font-weight: 500;
}

.el-tree-node__children {
  margin-left: 20px;
  border-left: 1px dashed var(--lightgray-color);
}
.action-buttons {
  display: flex;
  gap: 5px;
}

.dropdown-action {
  margin-right: 10px;
}

.upload-button {
  margin-left: 5px;
}
/*关联公式*/
.el-dialog {
  padding: 20px;
}

.el-dialog__footer {
  text-align: right;
}

.el-table {
  margin-top: 15px;
}

.el-table .el-table__header-wrapper th {
  background-color: var(--lightgray-color);
  color: var(--text-color);
  font-weight: bold;
  text-align: center;
}

.el-table th,
.el-table td {
  text-align: center;
  color: var(--text-color);
}

.el-button {
  margin: 0 5px;
}

/*关联公式*/
</style>
