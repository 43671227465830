import { createRouter, createWebHashHistory } from 'vue-router';
import UserLogin from '../components/User/UserLogin.vue';
import UserRegister from '../components/User/UserRegister.vue';
import MainHome from '../components/MainHome.vue';
import ReportCenter from '../components/Datas/ReportCenter.vue';
import EditReportDetails from '../components/Datas/EditReportDetails.vue';
import EditCompanyDetails from '../components/Datas/EditCompanyDetails.vue';
import EditDocumentDetails from '../components/Datas/EditDocumentDetails.vue';
import DocumentDefault from '../components/Datas/DocumentDefault.vue';
import EditVerification from '../components/Datas/EditVerification.vue';

const routes = [
    { path: '/', redirect: '/home' },
    { path: '/home', component: MainHome, meta: { requiresAuth: true } },
    { path: '/login', component: UserLogin },
    { path: '/register', component: UserRegister },
    { path: '/report-center', component: ReportCenter, meta: { requiresAuth: true } },
    {
        path: '/edit-report/:id/:temp_id',
        name: 'EditReportDetails',
        component: EditReportDetails,
        props: true,
        meta: { requiresAuth: true }
    },
    {
        path: '/edit-company/:id',
        name: 'EditCompanyDetails',
        component: EditCompanyDetails,
        props: true,
        meta: { requiresAuth: true }
    },
    {
        path: '/edit-verification/:id',
        name: 'EditVerification',
        component: EditVerification,
        props: true,
        meta: { requiresAuth: true }
    },
    {
        path: '/edit-document/:id',
        name: 'EditDocumentDetails',
        component: EditDocumentDetails,
        props: true,
        meta: { requiresAuth: true }
    },
    {
        path: '/document-default',
        name: 'DocumentDefault',
        component: DocumentDefault,
        meta: { requiresAuth: true }
    },
];

const router = createRouter({
    history: createWebHashHistory(), // 使用 hash 模式
    routes,
});

// 全局路由守卫
router.beforeEach((to, from, next) => {
    const isLoggedIn = localStorage.getItem('isLoggedIn');
    if (to.matched.some(record => record.meta.requiresAuth) && !isLoggedIn) {
        next('/login');
    } else {
        next();
    }
});

export default router;
