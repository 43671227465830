<template>
  <div class="edit-report-details">
    <!-- 上传说明 -->
    <div class="instruction">
      <p>说明：备注服务单位的基本信息，如联系人，地址等</p>
    </div>

    <!-- 表单区域 -->
    <div class="form">
      <el-form :model="form" label-width="150px" class="form-content">
        <el-form-item label="受核查方名称">
          <el-input v-model="form.companyName" placeholder="请输入受核查方名称"></el-input>
        </el-form-item>
        <el-form-item label="地址">
          <el-input v-model="form.location" placeholder="请输入所在地址"></el-input>
        </el-form-item>
        <el-form-item label="联系人">
          <el-input v-model="form.contactPerson" placeholder="请输入联系人姓名"></el-input>
        </el-form-item>
        <el-form-item label="联系方式">
          <el-input v-model="form.contactInfo" placeholder="请输入联系人联系方式"></el-input>
        </el-form-item>
        <el-form-item label="所属行业领域">
          <el-input v-model="form.industryField" placeholder="请输入受核查方所属行业领域"></el-input>
        </el-form-item>
        <el-form-item label="是否为独立法人">
          <el-input v-model="form.isIndependentLegalEntity" placeholder="请输入是否为独立法人"></el-input>
        </el-form-item>
        <el-form-item label="核算和报告依据">
          <el-input v-model="form.reportingBasis" placeholder="请输入核算和报告依据"></el-input>
        </el-form-item>
        <el-form-item label="气体种类">
          <el-input v-model="form.gasType" placeholder="请输入气体种类"></el-input>
        </el-form-item>
        <el-form-item label="温室气体排放量">
          <el-input v-model="form.emissionAmount" placeholder="请输入温室气体排放量"></el-input>
        </el-form-item>
        <!-- 按钮区域 -->
        <div class="button-group">
          <el-button type="primary" class="save-button" @click="submitForm">保存</el-button>
          <el-button type="default" class="cancel-button" @click="resetForm">取消</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { ElMessage } from "element-plus";

export default {
  name: "EditCompanyDetails",
  data() {
    return {
      form: {
        id: null, // 新增的字段
        companyName: "",
        location: "",
        contactPerson: "",
        contactInfo: "",
        industryField: "",
        isIndependentLegalEntity: "",
        reportingBasis: "",
        gasType: "",
        emissionAmount: "",
      },
    };
  },
  methods: {
    /**
     * 获取数据（用于回显）
     */
    async fetchCompanyDetails(companyId) {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.post(
            `${process.env.VUE_APP_HOST}/api/company/details`,
            { id: companyId },
            {
              headers: {
                Authorization: `Bearer ${token}`, // 如果需要鉴权
              },
            }
        );

        if (response.data && response.data.code === 1) {
          this.form = { ...response.data.data }; // 设置表单数据
        } else {
          ElMessage.error(response.data.msg || "获取公司详情失败");
        }
      } catch (error) {
        ElMessage.error("获取公司详情请求失败");
      }
    },
    /**
     * 提交表单
     */
    async submitForm() {
      try {
        if (!this.form.companyName || !this.form.contactPerson || !this.form.contactInfo || !this.form.industryField) {
          ElMessage.error("请填写所有必填项");
          return;
        }

        const token = localStorage.getItem("token");
        const url = this.form.id
            ? `${process.env.VUE_APP_HOST}/api/company/edit`
            : `${process.env.VUE_APP_HOST}/api/company/add`;

        const response = await axios.post(url, this.form, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (response.data && response.data.code === 1) {
          ElMessage.success("公司信息保存成功");

          // 延迟关闭页面
          setTimeout(() => {
            window.close(); // 关闭当前标签页
          }, 1000);
        } else {
          ElMessage.error(response.data.msg || "保存失败");
        }
      } catch (error) {
        ElMessage.error("提交失败，请稍后重试");
      }
    },
    /**
     * 重置表单
     */
    resetForm() {
      // 清空表单
      this.form = {
        id: null,
        companyName: "",
        location: "",
        contactPerson: "",
        contactInfo: "",
        industryField: "",
        isIndependentLegalEntity: "",
        reportingBasis: "",
        gasType: "",
        emissionAmount: "",
      };
    },
  },
  mounted() {
    const companyId = this.$route.params.id; // 获取路由参数
    if (companyId) {
      this.fetchCompanyDetails(companyId); // 根据 ID 获取公司详情
    }
  },
};
</script>


<style scoped>
.edit-report-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f5f5;
  padding: 20px;
}

.form, .instruction {
  background-color: #fff;
  width: 800px;
  padding: 20px 40px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-top: 5px;
}

.instruction {
  background-color: #FFF9DB;
  height: 10px;
  line-height: 10px;
  border-radius: 5px;
  color: #666;
  font-size: 14px;
}

.form-content {
  max-width: 100%;
}

.el-form-item {
  margin-bottom: 15px;
}

.el-input {
  width: 100%;
}

.el-form-item__label {
  font-weight: bold;
  white-space: nowrap;
  text-align: right; /* 标签居右显示 */
}

/* 按钮组样式 */
.button-group {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.save-button, .cancel-button {
  margin: 0 10px;
}

.save-button {
  display: flex;
  align-items: center;
  background-color: #409eff;
  color: white;
}

@media (max-width: 768px) {
  .form, .instruction {
    width: 90%;
  }
}
</style>
