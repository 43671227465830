<template>
  <div class="data-center-option">
    <div class="button-container">
      <el-button type="primary" class="create-default-button" @click="addCategory">
        <el-icon class="button-icon">
          <CirclePlus />
        </el-icon>
        <span class="button-text">新增大类</span>
      </el-button>
    </div>

    <!-- 多级菜单目录 -->
    <el-tree
        ref="directoryTree"
        :data="directoryData"
        :props="defaultProps"
        :default-expanded-keys="expandedKeys"
        node-key="id"
        highlight-current
        @node-click="handleNodeClick"
        class="directory-tree"
    >
      <template #default="{ node, data }">
        <div class="tree-node">
          <span class="node-label">{{ data.label }}</span>
          <span class="leader-dots"></span>
          <div class="action-buttons">
            <el-dropdown class="dropdown-action" v-if="data.hasUploadedTemplate">
              <el-button type="text" size="mini">
                操作模板
              </el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item v-if="data.originalFileName">
                    文件名：{{ data.originalFileName }}
                  </el-dropdown-item>
                  <el-dropdown-item @click="deleteTemplate(data.id)">
                    删除模板
                  </el-dropdown-item>
                  <el-dropdown-item @click="editTemplate(data.id)">
                    下载模板
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <el-button
                type="text"
                size="mini"
                class="upload-button"
                @click.stop="openUploadDialog(data)"
            >
              上传模板
            </el-button>
            <el-button type="text" size="mini" @click.stop="addSubDirectory(data)">新增子目录</el-button>
            <el-button type="text" size="mini" @click.stop="editNode(data)">编辑名称</el-button>
            <el-button type="text" size="mini" @click.stop="deleteNode(data)">删除目录</el-button>
          </div>
        </div>
      </template>

    </el-tree>

    <!-- 上传模板弹窗 -->
    <el-dialog title="上传模板" v-model="showUploadDialog" width="400px">
      <el-upload
          :action="''"
          :before-upload="onUploadConfirm"
          :on-change="handleFileChange"
          list-type="text"
      >
        <el-button type="primary">选择文件</el-button>
      </el-upload>
      <template #footer>
        <el-button @click="showUploadDialog = false" size="small">取消</el-button>
        <el-button type="primary" size="small" @click="onUploadConfirm">确认上传</el-button>
      </template>
    </el-dialog>

    <!-- 新增/编辑目录对话框 -->
    <el-dialog :title="isEditing ? '编辑目录' : '新增目录'" v-model="showEditDialog" width="400px">
      <el-form :model="currentNode" label-width="80px">
        <el-form-item label="目录名称">
          <el-input v-model="currentNode.label" placeholder="请输入目录名称"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="showEditDialog = false" size="small">取消</el-button>
        <el-button type="primary" @click="onSave" size="small">保存</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import { ElMessageBox, ElMessage } from 'element-plus';

export default {
  data() {
    return {
      directoryData: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      showEditDialog: false,
      isEditing: false,
      currentNode: {},
      currentParentNode: null,
      expandedKeys: [],
      showUploadDialog: false,
      uploadForm: {
        templateName: '',
        templateDescription: '',
      },
      selectedFile: null,
      selectedUploadNode: null,
    };
  },
  methods: {
    // 编辑模板内容
    editTemplate(id) {
      // 构造新窗口的 URL，假设跳转到 `/edit-template/:id`
      const baseUrl = `${window.location.origin}/#/edit-document`;
      const newWindowUrl = `${baseUrl}/${id}`;
      window.open(newWindowUrl, '_blank');
    },
    handleFileChange(file) {
      console.log("Selected file:", file);
      this.selectedFile = file.raw;
    },
    // 删除模板
    async deleteTemplate(nodeId) {
      try {
        await ElMessageBox.confirm('确定删除模板吗？此操作不可撤销。', '提示', {
          confirmButtonText: '删除',
          cancelButtonText: '取消',
          type: 'warning',
        });

        const token = localStorage.getItem('token');
        const response = await axios.post(
            `${process.env.VUE_APP_HOST}/api/default_contents/deleteNodeTemplate`,
            { nodeId },
            { headers: { Authorization: `Bearer ${token}` } }
        );

        if (response.data && response.data.code === 1) {
          this.$message.success('模板删除成功');
          this.fetchData(); // 刷新目录树
        } else {
          this.$message.error('模板删除失败：' + response.data.msg);
        }
      } catch (error) {
        this.$message.error('删除模板失败，请重试');
      }
    },

    // 下载模板
    async downloadTemplate(nodeId) {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.post(
            `${process.env.VUE_APP_HOST}/api/default_contents/getTemplateByNode`,
            { nodeId },
            { headers: { Authorization: `Bearer ${token}` } }
        );

        if (response.data && response.data.code === 1 && response.data.data.file) {
          const fileUrl = `${process.env.VUE_APP_HOST}${response.data.data.file.path}`;
          const originalFileName = response.data.data.file.originalFileName || '未命名文件.docx';

          // 使用 fetch 下载文件 blob
          const fileResponse = await fetch(fileUrl);
          const blob = await fileResponse.blob();

          // 创建一个临时的 URL 对象
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = originalFileName; // 设置文件名为 originalFileName
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          ElMessage.success(`文件 "${originalFileName}" 下载成功`);
        } else {
          ElMessage.error("获取文件信息失败");
        }
      } catch (error) {
        this.$message.error('下载模板失败，请重试');
      }
    },

    // 上传模板
    openUploadDialog(node) {
      this.selectedUploadNode = node;
      this.showUploadDialog = true;
    },
    async onUploadConfirm() {
      console.log("onUploadConfirm triggered");

      if (!this.selectedUploadNode) {
        ElMessage.error("未选择有效节点进行上传");
        return false;
      }
      if (!this.selectedFile) {
        ElMessage.error("请选择要上传的文件");
        return false;
      }

      // 获取节点名称
      const nodeName = this.selectedUploadNode.label;
      // 获取上传文件的原文件名
      const originalFileName = this.selectedFile.name;

      const formData = new FormData();
      formData.append("file", this.selectedFile);
      formData.append("nodeId", this.selectedUploadNode.id);
      formData.append("nodeName", nodeName);
      formData.append("originalFileName", originalFileName);

      try {
        const url = `${process.env.VUE_APP_HOST}/api/default_contents/uploadTemplate`;
        const token = localStorage.getItem('token');

        const response = await axios.post(
            url,
            formData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data"
              }
            }
        );

        if (response.data && response.data.code === 1) {
          ElMessage.success(`模板已成功上传到节点: ${this.selectedUploadNode.label}`);
          this.fetchData(); // 刷新数据
        } else {
          ElMessage.error("上传失败：" + response.data.msg);
        }
      } catch (error) {
        console.error("Upload failed:", error);
        ElMessage.error("文件上传失败");
      } finally {
        this.showUploadDialog = false;
        this.selectedFile = null;
        this.selectedUploadNode = null;
      }

      return false;
    },

    // 新增子目录功能
    async addSubDirectory(data) {
      try {
        const { value: subDirectoryName } = await this.$prompt('请输入子目录名称', '新增子目录', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /\S+/,
          inputErrorMessage: '子目录名称不能为空',
        });

        const token = localStorage.getItem('token');
        const response = await axios.post(
            `${process.env.VUE_APP_HOST}/api/default_contents/add`,
            {
              label: subDirectoryName,
              parent_id: data.id,
            },
            { headers: { Authorization: `Bearer ${token}` } }
        );

        if (response.data && response.data.code === 1) {
          this.$message.success('子目录添加成功');
          this.fetchData(); // 刷新目录树
        } else {
          this.$message.error('添加失败：' + response.data.msg);
        }
      } catch (error) {
        this.$message.error('添加子目录失败，请重试');
      }
    },

    async fetchData() {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${process.env.VUE_APP_HOST}/api/default_contents/list`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (response.data && response.data.code === 1) {
          this.directoryData = response.data.data.data;
          this.setExpandedKeys(this.directoryData);
        } else {
          console.error("Failed to fetch data:", response.data.msg);
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    },

    setExpandedKeys(data) {
      const getKeys = (nodes) => {
        let keys = [];
        nodes.forEach(node => {
          keys.push(node.id);
          if (node.children && node.children.length) {
            keys = keys.concat(getKeys(node.children));
          }
        });
        return keys;
      };
      this.expandedKeys = getKeys(data);
    },

    addCategory() {
      this.currentNode = { label: '', children: [] };
      this.isEditing = false;
      this.showEditDialog = true;
      this.currentParentNode = null;
    },

    handleNodeClick(node) {
      this.currentParentNode = node;
      this.$refs.directoryTree.setCurrentKey(node.id);
    },

    editNode(data) {
      this.currentNode = { ...data };
      this.isEditing = true;
      this.showEditDialog = true;
    },

    async deleteNode(data) {
      try {
        const token = localStorage.getItem('token');
        await ElMessageBox.confirm('确定删除该目录吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        });
        await axios.post(`${process.env.VUE_APP_HOST}/api/default_contents/delete`,
            { id: data.id },
            { headers: { Authorization: `Bearer ${token}` } }
        );
        ElMessage.success('删除成功');
        this.fetchData();
      } catch (error) {
        ElMessage.error("删除请求失败");
      }
    },

    async onSave() {
      try {
        const token = localStorage.getItem('token');
        if (this.isEditing) {
          await axios.post(`${process.env.VUE_APP_HOST}/api/default_contents/edit`, {
            id: this.currentNode.id,
            label: this.currentNode.label,
          }, {
            headers: { Authorization: `Bearer ${token}` },
          });
          ElMessage.success("编辑成功");
        } else {
          const response = await axios.post(`${process.env.VUE_APP_HOST}/api/default_contents/add`, {
            label: this.currentNode.label,
            parent_id: this.currentParentNode ? this.currentParentNode.id : 0,
          }, {
            headers: { Authorization: `Bearer ${token}` },
          });

          if (response.data && response.data.code === 1) {
            ElMessage.success("大类创建成功");
          } else {
            ElMessage.error("创建大类失败: " + response.data.msg);
            return;
          }
        }
        this.showEditDialog = false;
        this.fetchData();
      } catch (error) {
        ElMessage.error("操作失败: " + error.message);
      }
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style>
.add-category-button {
  background-color: var(--primary-color);
  color: var(--white-color);
  border: none;
}

.add-category-button:hover {
  background-color: var(--secondary-color);
  color: var(--white-color);
}

.create-default-button {
  background-color: var(--primary-color);
  color: var(--white-color);
  border: none;
}

.create-default-button:hover {
  background-color: var(--secondary-color);
  color: var(--white-color);
}

.action-buttons .el-button {
  background: none !important;
  border-color: transparent !important;
  color: var(--el-color-primary) !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.data-center-option {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 0;
  background-color: var(--background-color);
}

.button-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.directory-container {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}

.directory-tree {
  border: 1px solid var(--lightgray-color);
  border-radius: 4px;
}

.el-tree-node__content {
  height: 50px !important;
  display: flex;
  align-items: center;
  padding: 0 10px;
  transition: background-color 0.3s ease;
  border-radius: 4px;
}

.el-tree-node__content:hover {
  background-color: #f5f7fa;
}

.tree-node {
  display: flex;
  align-items: center;
  width: 100%;
}

.expand-icon {
  cursor: pointer;
  margin-right: 5px;
}

.root-node .node-label {
  font-size: 16px;
  color: #000;
  font-weight: bold;
}

.node-label {
  font-size: 14px;
  color: #333;
  font-weight: 500;
  margin-right: auto;
  white-space: nowrap;
}

.leader-dots {
  flex-grow: 1;
  border-bottom: 1px dotted #333;
  margin: 0 10px;
  height: 0;
}

.el-dialog__footer {
  text-align: right;
}

.el-form-item__label {
  font-weight: 500;
}

.el-tree-node__children {
  margin-left: 20px;
  border-left: 1px dashed var(--lightgray-color);
}
.action-buttons {
  display: flex;
  gap: 10px; /* 按钮间距 */
}

.dropdown-action {
  margin-right: 10px; /* 下拉框按钮右侧间距 */
}

.upload-button {
  margin-left: 5px; /* 上传模板按钮左侧间距 */
}
</style>
