<template>
  <div class="data-center-option">
    <!-- 新增公式按钮 -->
    <div class="button-container">
      <el-button type="primary" class="add-report-button" @click="openAddDialog">
        <el-icon class="button-icon">
          <CirclePlus />
        </el-icon>
        <span class="button-text">新增公式</span>
      </el-button>
    </div>

    <!-- 新增/编辑公式对话框 -->
    <el-dialog :title="isEditing ? '编辑公式' : '新增公式'" v-model="showDialog" min-width="700px">
      <el-form :model="currentFormula" label-width="120px" ref="formulaForm">
        <!-- 公式名称 -->
        <el-form-item label="公式名称" prop="name" :rules="[{ required: true, message: '请输入公式名称', trigger: 'blur' }]">
          <el-input v-model="currentFormula.name" placeholder="请输入公式名称"></el-input>
        </el-form-item>

        <!-- 公式描述 -->
        <el-form-item label="描述">
          <el-input v-model="currentFormula.description" type="textarea" placeholder="请输入公式描述"></el-input>
        </el-form-item>

        <!-- 公式表达式 -->
        <el-form-item label="公式表达式" prop="expression" :rules="[{ required: true, message: '请输入公式表达式', trigger: 'blur' }]">
          <el-input v-model="currentFormula.expression" placeholder="例如: F = A * B / 1000"></el-input>
        </el-form-item>

        <!-- 公式变量 -->
        <el-form-item label="公式变量">
          <el-table :data="currentFormula.variables" border size="small" style="width: 100%">
            <!-- 变量名 -->
            <el-table-column prop="name" label="变量名" width="120">
              <template #default="{ row }">
                <el-input
                    v-model="row.name"
                    placeholder="请输入变量名"
                    size="small"
                ></el-input>
              </template>
            </el-table-column>

            <!-- 类型 -->
            <el-table-column prop="type" label="类型" width="120">
              <template #default="{ row }">
                <el-select v-model="row.type" placeholder="选择类型" size="small">
                  <el-option label="变量" value="variable"></el-option>
                  <el-option label="常量" value="constant"></el-option>
                </el-select>
              </template>
            </el-table-column>

            <!-- 默认值 -->
            <el-table-column prop="default_value" label="默认值" width="120">
              <template #default="{ row }">
                <el-input
                    v-model="row.default_value"
                    placeholder="请输入默认值"
                    size="small"
                ></el-input>
              </template>
            </el-table-column>

            <!-- 描述 -->
            <el-table-column prop="description" label="描述">
              <template #default="{ row }">
                <el-input
                    v-model="row.description"
                    placeholder="请输入描述"
                    size="small"
                ></el-input>
              </template>
            </el-table-column>

            <!-- 操作 -->
            <el-table-column label="操作" width="100">
              <template #default="{ $index }">
                <el-button type="text" size="small" @click="removeVariable($index)">
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>


          <el-button type="text" @click="addVariable">新增变量</el-button>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="closeDialog">取消</el-button>
        <el-button type="primary" @click="onSubmit">{{ isEditing ? '保存' : '添加' }}</el-button>
      </template>
    </el-dialog>

    <!-- 表格列表 -->
    <el-table :data="paginatedData" border style="width: 100%;" stripe>
      <el-table-column prop="name" label="公式名称" />
      <el-table-column prop="description" label="描述" />
      <el-table-column prop="expression" label="公式表达式" />
      <!-- 操作列 -->
      <el-table-column label="操作" width="180">
        <template #default="{ row }">
          <div class="action-buttons">
            <el-button type="primary" size="small" @click="edit(row)">编辑</el-button>
            <el-button type="danger" size="small" @click="deleteRow(row)">删除</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页组件 -->
    <el-pagination
        style="margin-top: 20px; text-align: right;"
        background
        layout="prev, pager, next"
        :total="tableData.length"
        :page-size="pageSize"
        v-model:currentPage="currentPage"
        @current-change="handlePageChange"
    />
  </div>
</template>

<script>
import axios from 'axios';
import { CirclePlus } from '@element-plus/icons-vue';
import { ElMessageBox, ElMessage } from 'element-plus';

export default {
  name: "FormulaLibrary",
  components: {
    CirclePlus,
  },
  data() {
    return {
      tableData: [], // 保存公式数据
      currentPage: 1,
      pageSize: 10,
      showDialog: false,
      isEditing: false,
      currentFormula: {
        id: null,
        name: '',
        description: '',
        expression: '',
        variables: [], // 包含变量的数组
      },
    };
  },
  computed: {
    paginatedData() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = this.currentPage * this.pageSize;
      return Array.isArray(this.tableData) ? this.tableData.slice(start, end) : [];
    },
  },
  methods: {
    async fetchData() {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${process.env.VUE_APP_HOST}/api/formula/list`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.data && response.data.code === 1) {
          this.tableData = response.data.data.data;
        } else {
          console.error("Failed to fetch data:", response.data.msg);
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    },
    openAddDialog() {
      this.resetForm();
      this.isEditing = false;
      this.showDialog = true;
    },
    edit(row) {
      this.currentFormula = { ...row };
      this.isEditing = true;
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
      this.resetForm();
    },
    async addFormula() {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.post(`${process.env.VUE_APP_HOST}/api/formula/add`, this.currentFormula, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.data && response.data.code === 1) {
          ElMessage.success("公式添加成功");
          this.fetchData();
          this.closeDialog();
        } else {
          ElMessage.error(response.data.msg || "添加失败");
        }
      } catch (error) {
        ElMessage.error("添加请求失败");
      }
    },
    async updateFormula() {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.post(`${process.env.VUE_APP_HOST}/api/formula/edit`, this.currentFormula, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.data && response.data.code === 1) {
          ElMessage.success("公式更新成功");
          this.fetchData();
          this.closeDialog();
        } else {
          ElMessage.error(response.data.msg || "更新失败");
        }
      } catch (error) {
        ElMessage.error("更新请求失败");
      }
    },
    onSubmit() {
      this.$refs.formulaForm.validate((valid) => {
        if (valid) {
          if (this.isEditing) {
            this.updateFormula();
          } else {
            this.addFormula();
          }
        } else {
          ElMessage.error("请完善表单信息");
          return false;
        }
      });
    },
    resetForm() {
      this.currentFormula = {
        id: null,
        name: '',
        description: '',
        expression: '',
        variables: [],
      };
    },
    handlePageChange(page) {
      this.currentPage = page;
    },
    async deleteRow(row) {
      try {
        await ElMessageBox.confirm('确定删除这条记录吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        });

        const token = localStorage.getItem('token');
        const response = await axios.post(`${process.env.VUE_APP_HOST}/api/formula/delete`, { id: row.id }, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.data && response.data.code === 1) {
          this.tableData = this.tableData.filter(item => item.id !== row.id);
          ElMessage.success('删除成功');
        } else {
          ElMessage.error(response.data.msg || '删除失败');
        }
      } catch (error) {
        if (error !== 'cancel') {
          ElMessage.error('删除请求失败');
        }
      }
    },
    addVariable() {
      this.currentFormula.variables.push({
        name: '',
        type: 'variable',
        default_value: null,
        description: '',
      });
    },
    removeVariable(index) {
      this.currentFormula.variables.splice(index, 1);
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>
<style scoped>
.data-center-option {
  padding: 0;
  background-color: var(--background-color);
}

.button-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding-left: 0;
}

.add-report-button {
  background-color: var(--primary-color);
  color: var(--white-color);
  border: none;
}

.add-report-button:hover {
  background-color: var(--secondary-color);
  color: var(--white-color);
}

.el-table .el-table__header-wrapper th {
  background-color: var(--lightgray-color);
  color: var(--text-color);
  font-weight: bold;
  text-align: center;
}

.el-table th,
.el-table td {
  text-align: center;
  color: var(--text-color);
}

.el-link {
  color: var(--primary-color);
  text-decoration: underline;
}

.action-buttons {
  display: flex;
  gap: 5px;
}
</style>
